const initialState = {
  maxResults: null,
  maxSelectedMids: null,
  merchantsQueryUrl: '',
  minSearchChars: null,
  partnerAccountQueryUrl: '',
  usersQueryUrl: ''
}

export default (state = initialState) => state
